.handle-reset-password-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 40px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 24px 64px rgba(25, 32, 45, 0.24);
    border-radius: 16px;
}

.handle-form-reset-password-screen {
    display: flex;
    flex-direction: column;
    width: 330px;
}

.handle-h1-reset-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: #202124;
    margin-top: 50px
}

.handle-h2-reset-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 147%;
    text-align: center;
    color: #202124;
    margin: 0
}

.form-reset-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #0E2C35;
}