.index-box {
    background-color: #F2F5F8;
    border-color: #E9EBEE;
    border-width: 1px;
    border-radius: 16px;
    width: 215px;
    height: 120px;
    border-style: solid !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.04);
}

.p-title-index {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    color: #5A646E;
    margin-bottom: 1%;
}

.p-index {
    font-family: "Roboto";
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: #0E2C35;
    margin-top: 2%;
    margin-bottom: -5%;
}

.p-percent {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin-top: -5%;
}

.invoice-box {
    font-family: "Roboto";
    background-color: #F2F5F8;
    border-color: #E9EBEE;
    border-width: 1px;
    border-radius: 12px;
    height: 48px;
    border-style: solid !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 6px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.04);
}

.p-value {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    color: #0E2C35;
}

.p-date {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: #4b545c;
}