.default-dropdown {
    border-radius: 8px;
    border-width: 1px;
    border-color: #CCD1D6;
    background-color: #FFFFFF;
    height: 38px;
    color: #0E2C35;
    font-family: "Roboto";
    font-size: 14px;
    width: 100%;
    font-weight: 500;
    text-align: left;
}