.reload-button {
  border-radius: 8px;
  border-width: 1px;
  background-color: #FFFFFF;
  color: #0E2C357E;
  border-color: #CCD1D6;
  height: 38px;
  width: 38px !important;
}

.reload-button:hover {
  background-color: #FFFFFF !important;
  color: #1890FF !important;
  background-color: #FFFFFF;
  border-color: #1890FF;
}