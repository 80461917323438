.box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    height: 565px;
    border-radius: 32px;
    border: 1px solid #CCD1D6;
}

.inner-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110px;
}

.title {
    color: #3B454F;
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 10%;
    margin-bottom: -70px;
}


.cifra {
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #006493
}

.price {
    height: 70px;
    font-family: 'Poppins';
    font-size: 58px;
    font-weight: 700;
    letter-spacing: 1px;
    color: #006493;

}

.price-box {
    display: flex;
    align-items: center;
    gap: 5px;
}

.period {
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 1px;
    color: #ACB4BA;
    margin-top: -45px;
}

.total {
    font-family: 'Poppins';
    font-size: 16px;
    visibility: visible;
    line-height: 27px;
    letter-spacing: 1px;
    color: #ACB4BA;
    margin-top: -18px;
}

.button {
    border-radius: 8px;
    background: #2290B2;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #FFFFFF;
    font-family: 'Roboto';
    height: 55px;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 1px;
    margin-bottom: 25px;
    width: 85%;

}

button:hover {
    color: #FFFFFF !important;
    background-color: #0C7CA8 !important;
}

.toggle-box {
    display: flex;
    align-items: center;
    gap: 7px;
    color: #3B454F;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 1px;
    margin-top: -20px;
}

.plan-includes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    height: 120px;
    line-height: 24px;
    // letter-spacing: 1px;
    text-align: left;
    color: #3B454F;
    width: 85%;
    margin-top: -10px;
}