.card {
    padding: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.10);
    border-radius: 8;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border-width: 1px;
    border-color: #CCD1D6;
}

.cancel-button {
    background-color: #E9EBEE;
    color: #3B454F;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    width: 95px;
    height: 44px;
    border-radius: 8px;
    margin-right: 5px;
}

.save-button {
    background-color: #2290B2;
    color: #FFFFFF;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 700;
    width: 95px;
    height: 44px;
    border-radius: 8px;
    margin-left: 5px;
}