.logo {
    height: 150px;
    margin-left: 7%;
    margin-right: 7%;
    background-repeat: no-repeat;
    background-image: url('../Assets/logo_logistiko.svg');
    background-position: center;
    background-size: contain;
}

.header {
    font-family: "Roboto";
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
    color: "#0E2C35";
}

.user {
    font-family: "Roboto";
    padding-right: 16px;
    color: "#3B454F";
    font-weight: 700;
    font-size: 14px;
    display: "flex";
    align-items: center;
}

.logout-button {
    font-family: "Roboto";
    width: 200px;
    height: 40px;
    padding: 4px;
    margin-top: -4px;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    border-top: 1px solid #E9EBEE;
    border-radius: 0 0 4px 4px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
    background: #fbfbfb;
    display: flex;
}

.breadcrumb {
    font-family: "Roboto";
    margin: 16px 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: 600;
    color: "#5A646E";
}

.content {
    font-family: "Roboto";
    padding-right: 20px;
    text-align: "left";
    background-color: #FFFFFF
}

.menu {
    font-family: "Roboto";
    // letter-spacing: 1px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background-color: #F2F5F8;
    color: #2290B2 !important;
}


.ant-menu>.ant-menu-item-active {
    color: #F2F5F8 !important;
    background-color: #2290B2 !important;
    letter-spacing: 0px;
    opacity: 90%;
}

.ant-menu>.ant-menu-item-selected {
    font-family: "Roboto";
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background-color: #2290B2 !important;
    color: #E5F1FF;
}

.ant-menu>.ant-menu-item-selected:hover {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background-color: #2290B2 !important;
    color: #E5F1FF !important;
}

.ant-menu>.ant-menu-submenu-selected {
    font-family: "Roboto";
    letter-spacing: 0px;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2290B2 !important;
}

.ant-menu>.ant-menu-item:hover {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background-color: #2290B2 !important;
    color: #E5F1FF !important;
}

.ant-menu>.ant-menu-submenu-open {
    color: #2290B2 !important;
}