.handle-login-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 40px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 24px 64px rgba(25, 32, 45, 0.24);
    border-radius: 16px;
}

.handle-form-login-screen {
    font-family: 'Roboto';
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 330px;
    margin-top: 30px;
}

.p-resend-verification {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #202124;
    margin-top: -7px;
    margin-bottom: -7px;
}

.handle-h1-login-screen {
    font-family: 'Roboto';
    font-style: normal;
    color: #202124;
    margin-bottom: -25px;
}

.form-login-screen {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #0E2C35;
}

.handle-forgot-password-login-screen {
    display: flex;
    justify-content: right;
    font-family: 'Roboto';
    font-style: normal;
    margin-top: 8px;
    width: 100%
}

.forgot-password-login-screen {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 100%;
    color: #006493;
    margin-bottom: 15px;
}

.ant-form-item {
    font-family: 'Roboto';
    font-style: normal;
    margin-top: 10px;
    margin-bottom: 0;
}

.handle-p-login-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #202124;
}

.handle-link-sign-login-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #006493;
    text-decoration: underline
}