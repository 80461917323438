.header {
    width: 97%;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #3B454F;
}


.header-div {
    background-color: #F2F5F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 213px;
}

.header-div-payment {
    background-color: #F2F5F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 213px;
}

.h1 {
    font-family: 'Roboto';
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: #202124;
    padding-top: 5px;
}

.text {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3B454F;
    text-align: center;
    margin: 0;
}

.login-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 308px;
}

.login-buttons:hover {
    color: #FFFFFF;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 2%;
}
