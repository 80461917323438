.default-button {
    font-family: "Roboto";
    height: 40px;
    background: #2290B2;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.default-button:hover {
    color: #FFFFFF !important;
    background-color: #0C7CA8 !important;
}


.white-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "Roboto";
    height: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    border-color: #2290B2;
    font-weight: 700;
    font-size: 14px;
    color: #2290B2;
}