.default-card {
    font-family: "Roboto";
    font-weight: 700;
    border-color: #CCD1D6;
    border-width: 1px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.white-card {
    font-family: "Roboto";
    font-weight: 700;
    border-color: #CCD1D6;
    border-width: 1px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
    border-radius: 32px;
    overflow: hidden;
    margin-left: -5px;
    margin-right: -5px;
}


.default-card .ant-form-item-label {
    padding: 0 !important;
    padding-bottom: 4px !important;
    margin-left: -4px;
    color: red !important;
    font-size: 10px;
}