.modal-buttons {

  .btnFooter {
    display: flex !important;
    justify-content: space-between !important;
    width: 451px;
    margin-top: 20px;


    .btnConfirmRed {
      font-family: "Roboto";
      background: #FF3541;
      border-radius: 8px;
      color: #FFFFFF;
      width: 161px;
      height: 44px;
      font-weight: 700;
      font-size: 14px;
    }

    .btnConfirmRed:hover {
      font-family: "Roboto";
      background: #f00514;
      border-radius: 8px;
      color: #FFFFFF;
      width: 161px;
      height: 44px;
      font-weight: 700;
      font-size: 14px;
    }

    .btnConfirmBlue {
      font-family: "Roboto";
      background: #2290B2;
      border-radius: 8px;
      color: #FFFFFF;
      width: 161px;
      height: 44px;
      font-weight: 700;
      font-size: 14px;
    }

    .btnConfirmBlue:hover {
      font-family: "Roboto";
      background: #0C7CA8 !important;
      border-radius: 8px;
      color: #FFFFFF;
      width: 161px;
      height: 44px;
      font-weight: 700;
      font-size: 14px;
    }
    
    .btnCancel {
      font-family: "Roboto";
      border: 2px solid #E9EBEE;
      border-radius: 8px;
      color: #3B454F;
      background-color: #E9EBEE;
      width: 161px;
      height: 44px;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    font-family: "Roboto";
    color: #22272D;
    justify-content: center;
    margin: auto;
    width: 431px;
    font-weight: 700;
    font-size: 24px !important;
    text-align: center;
    margin-bottom: 15px;
  }

  .text {
    font-family: "Roboto";
    color: #5A646E;
    justify-content: center;
    margin: auto;
    width: 431px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
  }

  .ant-modal-content {
    font-family: "Roboto";
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 32px;
    border-radius: 16px;
    width: 100%;
  }


}