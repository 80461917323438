.background {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #006493;
    height: 100vh;
    width: 100vw;
}

.background-img {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-image: url('../../Assets/fundo_logistiko.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.ant-row {
    justify-content: center;
    align-content: center;
    height: 100%;
}