.loading-modal {
    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
    }
}

.loading-container {
    width: 110px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container img {
    width: 100%;
}

.transparent-background .ant-modal-content {
    background-color: transparent !important;
}