.handle-register-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 38px;
    gap: 56px;
    width: 40vw;
    background: #FFFFFF;
    box-shadow: 0px 24px 64px rgba(25, 32, 45, 0.24);
    border-radius: 16px;
}

.handle-confirm-account-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 56px 38px;
    gap: 56px;
    width: 25vw;
    background: #FFFFFF;
    box-shadow: 0px 24px 64px rgba(25, 32, 45, 0.24);
    border-radius: 16px;
}

.handle-form-register-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    padding: 0px;
    gap: 24px;
    width: 40vw;
    color: #fff
}

.handle-content-register-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-style: normal;
    padding: 0px;
    gap: 24px;
    width: 40vw;
    color: #fff
}


.p-account-confirm {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #202124;
    margin-top: -7px;
    margin-bottom: -7px;
}

.handle-h1-register-screen {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    width: 100%;
    color: #202124;
    margin: 0;
    height: 24px;
    font-weight: 700;
}


.handle-h1-confirm-screen {
    display: flex;
    font-family: 'Roboto';
    font-style: normal;
    width: 100%;
    color: #202124;
    justify-content: center;
    margin: 0;
    height: 24px;
    font-weight: 700;
    margin-bottom: 20px;
}

.form-register-screen {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #0E2C35;
    width: 100%;
}

.register-form-button-register-screen {
    font-family: 'Roboto';
    font-style: normal;
    background-color: #2290B2;
    color: #fff;
    font-weight: 700;
    line-height: 14px;
    margin-top: 20px;
}

.handle-p-register-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #202124;
}

.handle-link-sign-register-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #006493;
    text-decoration: underline
}