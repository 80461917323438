.p-title-h3 {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #5A646E;
    margin-left: 3px;
}

.p-plan-details {
    color: #0E2C35;
    font-family: "Roboto";
    font-size: 14px;
    letter-spacing: 0.2px;
}

.a-link-cancel {
    margin-top: 16px;
    font-weight: 500;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.4px;
}