.custom-table .ant-table-thead>tr>th {
    background-color: #F2F5F8;
    color: #5A646E;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    line-height: 12px;
    text-align: left;
    border-top: #CCD1D6 solid;
    border-bottom: #CCD1D6 solid;
    border-width: 1px;
    border-radius: 8px, 8px, 0px, 0px;
    
}

.custom-table .ant-table-tbody>tr>td {
    color: #3B454F;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    font-family: 'Roboto';
    border-bottom: #CCD1D6 solid;
    border-width: 1px;
    
}

.custom-row-red {
    background-color: #FFE1E3;
}

.custom-table .ant-table-tbody>tr.custom-row-red:hover td {
    background-color: #FFE1E3 !important;
}

.custom-table .ant-table-thead>tr>th:first-child {
    border-left: #CCD1D6 solid;
    border-width: 1px;
    
}

.custom-table .ant-table-thead>tr>th:last-child {
    border-right: #CCD1D6 solid;
    border-width: 1px;
    
}

.custom-table .ant-table-tbody>tr>td:first-child {
    border-left: #CCD1D6 solid;
    border-width: 1px;
    
}

.custom-table .ant-table-tbody>tr>td:last-child {
    border-right: #CCD1D6 solid;
    border-width: 1px;
    
}

.custom-table .ant-table-tbody>tr:last-child>td:first-child {
    border-radius: 0px 0px 0px 8px;
    
}

.custom-table .ant-table-tbody>tr:last-child>td:last-child {
    border-radius: 0px 0px 8px 0px;
    
}