.handle-forgot-password-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 56px 40px;
    gap: 56px;
    max-height: 100%;
    max-width: 20vw;
    background: #FFFFFF;
    box-shadow: 0px 24px 64px rgba(25, 32, 45, 0.24);
    border-radius: 16px;
}

.handle-h1-forgot-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: #202124;
    margin: 0
}

.handle-h2-forgot-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 147%;
    text-align: center;
    color: #202124;
    margin-top: -30px;
    margin-bottom: 0px;
}

.form-forgot-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    color: #0E2C35;
    width: 100%;
    margin-top: -50px
}

.handle-button-forgot-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    width: 100%;
    height: 38px;
    background: #2290B2;
    border-radius: 8px;
    font-weight: 700;
    color: #FFFFFF;
    margin-top: 10px;
}

.handle-p-forgot-password-screen {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    align-items: center;
    text-align: center;
    color: #202124;
    margin-bottom: 0
}